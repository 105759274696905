import { Component, OnInit } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {AuthService} from '../core/auth.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-list-companies',
  templateUrl: './list-companies.component.html',
  styleUrls: ['./list-companies.component.css']
})
export class ListCompaniesComponent implements OnInit {
  companiesCollection: AngularFirestoreCollection;
  companies: Observable<any[]>;

  constructor(public authService: AuthService, private afs: AngularFirestore) {
    this.companiesCollection = afs.collection('companies', ref => ref.orderBy('nextService', 'asc'));
    this.companies = this.companiesCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  ngOnInit() {
  }

  checkDays(lastService: any, serviceInterval: number) {
    const temp = new Date(lastService.toDate())
    const nextservice = lastService.toDate();
    const month = temp.getMonth();
    nextservice.setMonth((+month) + (+serviceInterval));
    const difference = Math.ceil(Math.abs(nextservice.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));

    console.log(difference);
    return difference;
  }
}
