import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {Form} from '@angular/forms';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  companyID: string;

  private companyDoc: AngularFirestoreDocument;
  company: Observable<any>;

  companyItem = {
    name: '',
    lastService: '',
    nextService: new Date(),
    serviceInterval : 0
  };

  constructor(private route: ActivatedRoute, private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.companyID = this.route.snapshot.paramMap.get('id');
    this.companyDoc = this.afs.doc('companies/' + this.companyID);
    this.company = this.companyDoc.valueChanges();
    this.company.subscribe((a) => {
      const month = '0' + (a.lastService.toDate().getMonth() + 1);
      this.companyItem.name = a.name;
      this.companyItem.lastService = a.lastService.toDate().getFullYear() + '-' + month + '-' + a.lastService.toDate().getDate();
      this.companyItem.nextService = a.nextService.toDate();
      this.companyItem.serviceInterval = a.serviceInterval;
    }
);
    console.log(this.companyItem);
  }

  onSubmit(f: Form) {
    const lastServiceTemp = new Date(this.companyItem.lastService);
    this.companyItem.serviceInterval = this.companyItem.serviceInterval;
    this.companyItem.nextService = new Date(lastServiceTemp.getFullYear(),
      lastServiceTemp.getMonth() + (+this.companyItem.serviceInterval),
      lastServiceTemp.getDate());

    this.companyDoc.set({
      name: this.companyItem.name,
      lastService: lastServiceTemp,
      nextService: this.companyItem.nextService,
      serviceInterval: this.companyItem.serviceInterval
    });

    alert('Успешна промена');
  }
}
