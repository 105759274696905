import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { CompanyComponent } from './company/company.component';
import { ListCompaniesComponent } from './list-companies/list-companies.component';
import { AddCompanyComponent } from './add-company/add-company.component';
import {RouterModule, Routes} from '@angular/router';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {FormsModule} from '@angular/forms';
import {AuthGuard} from './core/auth.guard';
import {AngularFirestore} from '@angular/fire/firestore';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: '', redirectTo: '/companies', pathMatch: 'full'},
  { path: 'addnew', component: AddCompanyComponent, canActivate: [AuthGuard]},
  { path: 'company/:id', component: CompanyComponent, canActivate: [AuthGuard]},
  { path: 'companies', component: ListCompaniesComponent, canActivate: [AuthGuard]}
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CompanyComponent,
    ListCompaniesComponent,
    AddCompanyComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule
    ],
  providers: [AuthGuard, AngularFirestore],
  bootstrap: [AppComponent]
})
export class AppModule { }
