import { Component, OnInit } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Form} from '@angular/forms';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css']
})
export class AddCompanyComponent implements OnInit {

  datetemp: any;

  companyItem = {
    name: '',
    lastService: '',
    nextService: new Date(),
    serviceInterval : 6
  };

  companiesCollection: AngularFirestoreCollection;
  companies: Observable<any[]>;

  constructor(private route: ActivatedRoute, private afs: AngularFirestore) {
    this.companiesCollection = afs.collection('companies');
    this.companies = this.companiesCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  ngOnInit() {
  }

  onSubmit(f: Form) {
    this.companiesCollection.add({
      name: this.companyItem.name,
      lastService: new Date(this.companyItem.lastService.toString()),
      nextService: this.companyItem.nextService,
      serviceInterval: this.companyItem.serviceInterval
    });
    alert('Успешно додадовте нова компанија');
  }

  calculate(lastService: any, serviceInterval: number) {
    const dateTemp = new Date(lastService);
    this.datetemp = dateTemp;
    this.companyItem.nextService = dateTemp;
    this.companyItem.nextService.setMonth(dateTemp.getMonth() + (+serviceInterval));
  }

}
